<app-auth-header page="home"></app-auth-header>

<div class="content">
    <div class="content-home">

        <app-banner-dashboard *ngIf="true || banners" [banners]="banners"></app-banner-dashboard>

        <div class="welcome-back">
            <span class="message">Seja bem-vindo(a)<span *ngIf="welcome_back"> de volta</span>, {{username}}</span>
            <span class="day">{{dateFormatService.getDateString()}}</span>
        </div>

        <div class="container-tabs">
            <div class="tabs">
                <input type="radio" id="all" name="tabs-home" [checked]="show_account_type == 'all'" (click)="filterAccounts('all')"/>
                <label class="tab" for="all">Todas</label>
                <input type="radio" id="enabled" name="tabs-home" [checked]="show_account_type == 'enabled'" (click)="filterAccounts('enabled')"/>
                <label class="tab" for="enabled">Ativas</label>
                <input type="radio" id="disabled" name="tabs-home" [checked]="show_account_type == 'disabled'" (click)="filterAccounts('disabled')"/>
                <label class="tab" for="disabled">Inativas</label>
                <span class="glider"></span>
            </div>
        </div>

        <div class="title-accounts">Contas INSIDER</div>

        <div class="list-accounts">
            <div class="card"  *ngFor="let account of filter_blackarrow">
                <div class="card-header">
                    <div class="account-info"><span>Sua conta será disponibilizada em até dois dias úteis após a confirmação do pagamento</span></div>
                    <div class="platform">
                        <img src="assets/icons/blackarrow.svg">
                        <div class="platform-name">Black Arrow</div>
                    </div>
                </div>
    
                <div class="section-info">
                    <div class="section-info-item">
                        <div class="section-info-item-description">Status:</div>
                        <div class="section-info-item-value color-warning" *ngIf="account.status == 2 && !account.account_operation">Aguardando configuração</div>
                    </div>
                </div>
            </div>
            <div class="empty" *ngIf="!filter_accounts || filter_accounts.length == 0">
                <span>Nenhuma conta encontrada</span>
            </div>
            <div class="card" *ngFor="let account of filter_accounts">
                <div class="mobile-menu" *ngIf="account.status == 2 || account.activated">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngIf="account.status == 2 && (account.platform_type == 1 && (account.accept_value_retention || account.coupon_id > 0))"
                            (click)="viewCredentials(account)">
                            <app-button-icon-label icon="unsecurity" description="Credenciais"></app-button-icon-label>
                        </button>
                        <button mat-menu-item *ngIf="account.activated" [routerLink]="'/desempenho/' + account.account">
                            <app-button-icon-label icon="graph" description="Desempenho"></app-button-icon-label>
                        </button>
                        <button mat-menu-item *ngIf="account.status == 2 && !account.activated && account.platform_type == 1" (click)="openInNewTab('/configurar-conta', account)">
                            <app-button-icon-label icon="config" description="Iniciar configuração da conta"></app-button-icon-label>
                        </button>
                        <button mat-menu-item *ngIf="account.status == 2 && account.platform_type == 1" (click)="openInNewTab('/configurar-conta-celular', account)">
                            <app-button-icon-label icon="mobile-phone" description="Configurar conta pelo telefone"></app-button-icon-label>
                        </button>
                    </mat-menu>
                </div>
                <div class="card-header">
                    <div class="plan">
                        <span>{{ account.plan.title }}</span>&nbsp;
                        <span *ngIf="!account.plan.is_stock">(Índices e derivativos)</span>
                        <span *ngIf="account.plan.is_stock">(Ações Americanas)</span>
                    </div>
                    <div class="platform" *ngIf="account.platform_type == 1">
                        <img src="assets/icons/metatrader.svg">
                        <div class="platform-name">Meta trader 5</div>
                    </div>
                    <div class="platform" *ngIf="account.platform_type == 2">
                        <img src="assets/icons/blackarrow.svg">
                        <div class="platform-name">Black Arrow</div>
                    </div>
                </div>
                <div class="card-header">
                    <div class="account-info">Número da conta: <span>{{account.account}}</span></div>
                </div>
                <div class="selo" *ngIf="account.type == 2">
                    Conta Real
                </div>
    
                <div class="section-info">
                    <div class="section-info-item">
                        <div class="section-info-item-description">Saldo:</div>
                        <div class="section-info-item-value">$ {{(account.account_balance ?? account.plan.value_account) | number:'1.2-2'}}</div>
                    </div>
                    <div class="section-info-item">
                        <div class="section-info-item-description">Status:</div>
                        <div class="section-info-item-value color-primary-medium" *ngIf="account.status == 2 && account.activated">Ativa</div>
                        <div class="section-info-item-value color-primary-medium" *ngIf="account.status == 4">Aprovado</div>
                        <div class="section-info-item-value color-info" *ngIf="account.status == 8">Aguardando aprovação</div>
                        <div class="section-info-item-value color-info" *ngIf="account.status == 9">Aguardando assinatura</div>
                        <div class="section-info-item-value color-warning" *ngIf="account.status == 2 && !account.activated">Aguardando configuração</div>
                        <div class="section-info-item-value color-danger" *ngIf="(account.status > 2 && account.status != 4) && account.test_account_operation.status == 3">Reprovada</div>
                        <div class="section-info-item-value color-danger" *ngIf="(account.status > 2 && account.status != 4) && account.test_account_operation.status != 3 && account.status != 8">Desativada</div>
                    </div>
                    <div class="section-info-item" *ngIf="account.platform_type == 1 && account.activated && account.test_account_operation.status == 1 && account.type == 1 && (account.status <= 2)">
                        <div class="section-info-item-description">Resultado:</div>
                        <div class="section-info-item-value color-warning">Em teste</div>
                    </div>
                </div>
    
                <div class="section-info" *ngIf="account.status == 2 && !account.lifetime && account.end_date_plan">
                    <div class="section-info-item">
                        <div class="section-info-item-description">Data de renovação:</div>
                        <div class="section-info-item-value">{{account.end_date_plan | date:'dd/MM/yyyy'}}</div>
                    </div>
                </div>
                <div class="section-buttons">
                    <app-button-icon-label icon="unsecurity" description="Credenciais" *ngIf="account.status == 2 && (account.platform_type == 1 && (account.accept_value_retention || account.coupon_id > 0))" (click)="viewCredentials(account)"></app-button-icon-label>
                    <app-button-icon-label icon="graph" description="Desempenho" [routerLink]="'/desempenho/' + account.account" *ngIf="account.activated"></app-button-icon-label>
                    <app-button-icon-label icon="config" description="Iniciar configuração da conta" (click)="openInNewTab('/configurar-conta', account)" *ngIf="account.status == 2 && !account.activated && account.platform_type == 1"></app-button-icon-label>
                    <app-button-icon-label icon="mobile-phone" description="Configurar conta pelo telefone" (click)="openInNewTab('/configurar-conta-celular', account)" *ngIf="account.status == 2 && account.platform_type == 1"></app-button-icon-label>
                </div>
            </div>
        </div>

        <section class="section-plans">
            <app-card-plan></app-card-plan>
        </section>
    </div>
</div>
<app-simple-header></app-simple-header>

<div class="content">
    <div class="tournament" *ngIf="tournament">
        <div class="title">{{tournament.title}}</div>
        <img *ngIf="tournament?.image" [src]="tournament.image">
        <img *ngIf="!tournament?.image" src="assets/images/trophy.png">
    </div>

    <div class="card" *ngIf="!success_add">
        <div class="card-title">Preencha o formulário abaixo para participar do torneio:</div>

        <form [formGroup]="formValidation">
    
            <div class="input">
                <span id="label-email" class="input-text">Email*</span>
                <input class="input-box" type="text" placeholder="Informe seu email" [class.input-box-error]="submitted && (errorCtr.email.errors?.required || errorCtr.email.errors?.pattern)"
                formControlName="email" (focus)="focus('focus', 'email')" (blur)="focus('unfocus', 'email')">
    
                <div class="error-form" *ngIf="submitted && errorCtr.email.errors?.required">
                    O campo e-mail é obrigatório
                </div>
                <div class="error-form" *ngIf="submitted && errorCtr.email.errors?.pattern">
                    Preencha corretamente o campo
                </div>
            </div>
    
            <div class="input">
                <span id="label-name" class="input-text">Nome completo*</span>
                <input class="input-box" type="text" (focus)="focus('focus', 'name')" [class.input-box-error]="submitted && errorCtr.name.errors?.required"
                    (blur)="focus('unfocus', 'name')" formControlName="name" placeholder="Informe seu nome completo">
    
                <span class="error-form" *ngIf="submitted && errorCtr.name.errors?.required">
                    O campo nome é obrigatório
                </span>
            </div>
            
            <div class="input">
                <span id="label-phone_number">Telefone*</span>
                <input class="input-box" type="text" placeholder="Informe seu telefone" [class.input-box-error]="submitted && errorCtr.phone_number.errors?.required"
                formControlName="phone_number" (focus)="focus('focus', 'phone_number')" (blur)="focus('unfocus', 'phone_number')" 
                mask="(00) 0000-0000||(00) 0 0000-0000">
                
                <span class="error-form" *ngIf="submitted && errorCtr.phone_number.errors?.required">
                    O campo telefone é obrigatório
                </span>
            </div>
        </form>

        <button class="button-primary" (click)="doCaptchValidation()">Salvar</button>
    </div>

    <div class="success" *ngIf="success_add">

        <div class="title">Olá <span class="color-primary">{{first_name}}</span>. {{message}}</div>

        <button class="button-primary" [routerLink]="'/login'">Acessar plataforma</button>
    </div>
</div>